.rank-score-slider .flickity-slider {
    height: 450px;
    display: flex;
    align-items: center;
}

.rank-score-slider .flickity-viewport {
    height: 400px !important;
}

.sajid[aria-hidden="true"] {
    opacity: 0.4;
    z-index: 0;
}

.sajid {
    opacity: 1;
    z-index: 9;


}
